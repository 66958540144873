.main-footer {
    /* background-color: #FFC400; */
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  /* NEW CODE */
  .footer {
    background-color: #3b58795d;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .footer-main {
    display: flex;
    justify-content: space-around;
    margin-left: 40px;
    margin-bottom: 20px;
    /* color: black; */
  }
  .account {
    display: flex;
    justify-content: space-between;
  }
  
  .lines {
    width: 52%;
  }
  .lower-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #000;
  }
  /* NEW CODE */
  .container {
    margin: 0 auto;
  }
  .footer-box {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  .row {
    display: flex;
    width: 100%;
  }
  .row-cr {
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .col {
    justify-content: center;
    text-align: left;
    font-size: 11px;
  }
  hr {
    width: 100%;
  }
  h3 {
    margin-bottom: 10px;
    font-size: 21px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 5px;
    font-size: 13px;
  }
  
  .col p {
    text-align: center;
  }
  
  /* .account-footer {
    text-align: center;
  } */
  
  .account-icons {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }
  
  .account {
    margin: 0 10px; /* Spasi antara ikon */
  }
  
  .logo-iconFoot {
    width: 30px; /* Sesuaikan lebar ikon */
    height: auto; /* Biarkan tinggi ikon disesuaikan secara otomatis */
  }
  
  /* .logo-iconFoot{
    width: 28px;
    height: auto;
  } */

  .foot-nama{
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .footer-main {
      flex-direction: column;
      padding: 20px;
      font-size: 14px;
      padding-bottom: 0;
    }
    .account {
      justify-content: start;
    }
    .lines {
      width: 90%;
    }
  }
  